import { memo } from "react";

import BB_Button from "@/Components/BB_Button";
import WarningIcon from "@/Icons/warning.svg?react";
import BB_Category from "@/types/BB_Category";

export type BBQuickSelectCategoriesProps = {
	categories: BB_Category[];
};

const BB_QuickSelectCategories = (props: BBQuickSelectCategoriesProps) => {
	return (
		<>
			{props.categories.length > 0 && (
				<div className="flex flex-wrap gap-2">
					{props.categories.map((category) => (
						<BB_Button
							key={category.id}
							type="plain"
							title={category.title || category.name}
							buttonClassName="px-3 py-1 rounded-full text-sm font-semibold text-gray-700! dark:text-gray-200! bg-background-50 dark:bg-dark-background-400 hover:bg-background-100 dark:hover:bg-dark-background-350 shadow-lg"
							onClick={route("explore", { b_id: category.b_id, slug: category.slug })}
						/>
					))}
				</div>
			)}

			{props.categories.length === 0 && (
				<div className="flex w-full flex-col items-center justify-center py-10 text-gray-500 dark:text-gray-400">
					<WarningIcon className="mb-2 h-12 w-12" />
					<span className="text-sm font-medium">Keine weiteren Filter vorhanden</span>
				</div>
			)}
		</>
	);
};

export default memo(BB_QuickSelectCategories);
